import React from 'react'
import { Link } from 'react-router-dom';
import '../styles/Header.css'

const Header = () => {

    const btnToShow = window.location.pathname.split('/')[1]

    return (
        <nav className="navbar navbar-expand-lg nav-color mb-3">
            <div className="container-fluid">
                <Link to="/">
                    <img className="navbar-brand" src='./assets/logo.svg' alt='logo' />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
                    <span className="navbar-text">
                        <div className="d-flex gap-3">
                            <span className="navbar-text">
                                <div className="d-flex gap-3">
                                    <Link to="/login" >
                                        <button className="header-btn btn-login-white">Log In</button>
                                    </Link>
                                    {
                                        btnToShow !== 'signup' && <Link to="/signup/psychologist">
                                            <button className="header-btn btn-start-white">Get Started</button>
                                        </Link>
                                    }
                                </div>
                            </span>
                        </div>
                    </span>
                </div>
            </div>
        </nav>
    )
}

export default Header