import { React, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HeaderLayout from './components/HeaderLayout';
import { Toaster } from 'react-hot-toast';
import ForgetPass from './pages/ForgetPass';
import ResetPassword from './pages/ResetPassword';

const Landing = lazy(() => import('./pages/Landing'))
const Page404 = lazy(() => import('./components/404/Page404'));

function App() {
  console.log("Build Date: 20-MAY-2024")
  return (
    <>
      <Router>
        <Suspense fallback={<>Loading...</>}>
          <Routes>
            <Route element={<HeaderLayout />}>
              <Route path='/login' element={<Landing page="login" heading="Best Assessment Automation Tool" content="AI Assessment is an all-in-one digitization tool that helps psychologists to digitize their assessments & reports and helps the end users to take assessment in an interactive environment" />} />
              <Route path='/signup/psychologist' element={<Landing type="Psychologist" page="signup" heading="Best Assessment Automation Tool for Psychologists" content="AI Assessment is an all-in-one digitization tool that helps psychologists to digitize their assessments & reports and helps the end users to take assessment in an interactive environment" />} />
              <Route path='/signup/patient' element={<Landing type="Patient" page="signup" heading="Best Assessment Automation Tool for Patients" content="AI Assessment is an all-in-one digitization tool that helps psychologists to digitize their assessments & reports and helps the end users to take assessment in an interactive environment" />} />
              <Route path='/' element={<Landing heading="Best Assessment Automation Tool for Psychologists" content="AI Assessment is an all-in-one digitization tool that helps psychologists to digitize their assessments & reports and helps the end users to take assessment in an interactive environment" />} />
              <Route path='*' element={<Page404 />} />
            </Route>
            <Route path='/forget-password' element={<ForgetPass />} />
            <Route path='/change-password/:token' element={<ResetPassword />} />
          </Routes>
        </Suspense>
        <Toaster
          position="top-center"
          reverseOrder={false}
        />
      </Router>
    </>
  );
}

export default App;
